<template>
    <div>
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CModal
        :title="titleModal"
        color="dark"
        @update:show="closeModal()"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
      >
      <CCol sm="12" class="d-flex align-items-center justify-content-center">
        <CCol sm="12" lg="11">
          <CRow>
            <CCol sm="12">
              <CSelect
                :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3', input: 'col-sm-12 col-lg-9 col-xl-9'}"
                :label="$t('label.type')"
                addLabelClasses="text-right required"
                :options="SummaryListFormatted"
                v-model.trim="Summary"
                :value.sync="Summary"
              />
              <CSelect
                :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
                :label="$t('label.transport')"
                addLabelClasses="text-right"
                v-model.trim="Transportation"
                :value.sync="Transportation"
                :options="transportationList"
              />
            </CCol>
          </CRow>
        </CCol>
      </CCol>
        
        
        <template #footer>
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="submit()"
            :disabled="isSubmit"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
          <CButton 
            color="wipe" 
            class="d-flex align-items-center"
            @click="closeModal()"
          >
            <CIcon name="x" /><span class="ml-1"
              >{{$t('button.cancel')}}</span
            >
          </CButton>
        </template>
      </CModal>
    </div>
</template>
<script>
  import { mapState } from 'vuex';
  import GeneralMixin from '@/_mixins/general';
  import UpperCase from '@/_validations/uppercase-directive';
  import { required, maxLength } from 'vuelidate/lib/validators';
  
  function data() {
    return {
      Summary: '',
      Transportation: '',
      TransportationOptions: [],
      modalActive: false,
      loadingOverlay: false,
      isSubmit: false,
    };
  }

  function submit() {
    try{
      /*this.loadingOverlay = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }
      let LogisticJson = {
        StowagePlanningLogWeigthId: this.StowagePlanningLogWeigthId,
        Observation: this.Observation,
      }
      this.$http
        .put('StowagePlanningLogisticWeigthOrder-Deactivate', LogisticJson, { root: 'LogisticJson' })
        .then((response) => {
          if (response && response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.closeModal();
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: messageSuccess,
              type: "success"
            });
          } 
        }).catch((e) => {
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.$notify({
              group: "container",
              title: "¡Error!",
              text: e,
              type: "error",
            });
          });*/
    }catch(e){
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: e,
        type: "error",
      });
    }
  }

  function getTransportationList() {
    //this.loadingOverlay = true;
    this.$http.get("VisitCarrier-list", { VisitId: this.Visit })
      .then(response => {
        this.TransportationOptions = response.data.data;
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
      //this.loadingOverlay = false;
    });
  }
  
  function closeModal() {
    this.Summary = '';
    this.Transportation = '';
    this.TransportationOptions = [];
    this.$emit('close-Modal');
  }
  
  function titleModal(){
    return this.$t('label.download')+' PDF';
  }

  function SummaryListFormatted(){
    return [{
      value: '', 
      label: this.$t('label.select'),
    },{
      value: 0, 
      label: this.$t('label.resumido'),
    },{
      value: 1, 
      label: this.$t('label.Detallado'),
    }];
  }

  function transportationList(){
    if(this.TransportationOptions.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.TransportationOptions.map(function(e){
        chart.push({
          value: e.StowagePlanningResourceId, 
          label: e.ClientName,
        })
      })
      return chart;
    }
  }
  
  export default {
    name: 'modal-transport-certification-pdf',
    props: { modal: Boolean },
    data,
    mixins: [
      GeneralMixin, 
    ],
    validations: {},
    directives: UpperCase,
    methods: {
      submit,
      getTransportationList,
      closeModal,
    },
    computed: {
      titleModal,
      SummaryListFormatted,
      transportationList,
      ...mapState({
        Visit: state => state.visitas.VisitId,
      }),
    },
    watch: {
      modal: async function(val){
        this.modalActive = val;
        if (val) {
          this.getTransportationList();
        }
      },
    },
    
  };
</script>